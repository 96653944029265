.menu-background {
	backdrop-filter: blur(10px) opacity(0);
  transition: backdrop-filter 0.2s ease;
  will-change: backdrop-filter;
}

.menu-background::after {
  content: '';
  position: fixed;
  inset: 0;
  /* Color is based on var(--color-glass) but less opacity for readability */
  background-color: rgba(250, 250, 250, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.menu-background.visible {
  backdrop-filter: blur(10px) opacity(1);
}

.menu-background.visible::after {
  opacity: 1;
}
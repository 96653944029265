.card {
	height: auto;
	max-width: none;
	width: 990.41px;
}

.copy {
	font-family: Favorit;
	font-size: 28px;
	font-weight: 300;
	height: 391px;
	left: 362px;
	line-height: 32px;
	top: 56px;
	width: 526px;
}

.header {
	font-family: Favorit;
	font-size: 46px;
	font-weight: 300;
	line-height: 48px;
}

.img {
	height: 246px;
	left: 55px;
	top: 55px;
	width: 248px;
}

.led {
	left: -186px;
	bottom: -1px;
}

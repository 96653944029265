iframe[src*="youtube"] {
	aspect-ratio: 16 / 9;
	width: 100%;
}

.content ol,
.content ul {
	margin: unset;
	padding: 0 0 0 40px;
}

.content ol {
	list-style-type: decimal;
}

.content ul {
	list-style-type: disc;
}

.content strong {
	font-family: Favorit Bold;
	text-transform: uppercase;
}

.content p,
.content strong {
	font-weight: 500;
}

.content p:has(strong):not(:first-of-type) {
	display: block;
	margin-top: 32px;
}

.content p {
	margin: 12px 0;
}

.content a {
	font-family: Favorit Bold;
	text-decoration: underline;
	display: inline-block;
}

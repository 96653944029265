@tailwind base;
@tailwind components;
@tailwind utilities;

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

:root {
	--color-black: #000000;
	--color-blue: #0056ff;
	--color-glass: rgba(250, 250, 250, 0.3);
	--color-gray-1: #c4c4c4;
	--color-gray-2: #707070;
	--color-orange: #f85730;
	--color-red: #c53c3a;
	--color-surface: #fafafa;
	--color-white: #ffffff;
}

body {
	color: var(--color-orange);
}

b,
strong {
	font-weight: 700;
}

.glass-pane {
	background: var(--color-glass);
	backdrop-filter: blur(10px) opacity(1);
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.fade-in {
	animation: fade-in 150ms ease-out 1 forwards;
}

.z-100 {
	z-index: 100;
}

.btn {
	align-items: center;
	background-color: var(--color-gray-1);
	cursor: pointer;
	display: flex;
	font-family: "Favorit Bold";
	font-size: 14px;
	font-weight: 500;
	height: 48px;
	justify-content: center;
	line-height: 100%;
	padding: 0 24px;
	text-align: center;
	user-select: none;
}

.btn:not([disabled]):hover {
	background-color: var(--color-black);
	color: var(--color-white);
	text-decoration: underline;
}

.btn[disabled] {
	cursor: default;
	opacity: 0.2;
}

.input {
	appearance: none;
	background-color: var(--color-gray-1);
	border-radius: 0;
	border: 1px solid var(--color-gray-1);
	font-weight: inherit;
	outline: none;
	padding: 8px;
	transition: background-color 250ms;
}

.input:focus {
	background-color: var(--color-white);
	outline: none;
}

.pill {
	border-radius: 46px;
	border: 1px solid black;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	font-family: "Favorit Bold";
	font-size: 14px;
	font-weight: 500;
	height: 32px;
	line-height: 16px;
	padding: 9px 16px 7px 16px;
	user-select: none;
	white-space: nowrap;
}

.pill.invert {
	background: black;
	color: white;
}

@media (hover: hover) {
	.pill:hover {
		background: black;
		color: white;
	}
}

.pill[disabled] {
	cursor: default;
	opacity: 0.2;
}

.slick-dots li {
	margin: 0;
}

.slick-dots li button:before {
	color: var(--color-gray-2) !important;
}

.slick-dots .slick-active button:before {
	color: var(--color-gray-2) !important;
}

.duration-2000 {
	transition-duration: 2000ms;
}

.duration-750 {
	transition-duration: 750ms;
}

.font-display {
	font-family: Favorit;
	font-size: 96px;
	font-weight: 300;
	letter-spacing: -0.035em;
	line-height: 80px;
}

.font-header-1 {
	font-family: Favorit;
	font-size: 23px;
	font-weight: 300;
	line-height: 24px;
}

.font-header-2 {
	font-family: "Favorit Bold";
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
}

.font-body {
	font-family: Favorit;
	font-size: 14px;
	font-weight: 300;
	line-height: 16px;
}

.font-caption {
	font-family: Favorit;
	font-size: 8.65px;
	font-weight: 300;
	line-height: 8px;
}

.font-studio-header {
	font-family: "Favorit Bold";
	font-size: 23px;
	font-weight: 500;
	line-height: 24px;
}

@media (max-width: 1023px) {
	.font-display {
		font-size: 50.83px;
		line-height: 48px;
	}

	.font-header-1 {
		font-size: 19.42px;
		line-height: 20px;
	}

	.font-header-2 {
		font-size: 12px;
		line-height: 12px;
	}

	.font-body {
		font-size: 12px;
		line-height: 16px;
	}

	.font-caption {
		font-size: 7.42px;
		line-height: 8px;
	}

	.font-studio-header {
		font-size: 19.42px;
		line-height: 20px;
	}
}

@media (max-width: 767px) {
	.font-display {
		font-size: 38px;
		line-height: 32px;
	}

	.font-header-1 {
		font-size: 25.89px;
		line-height: 24px;
	}

	.font-header-2 {
		font-size: 16px;
		line-height: 16px;
	}

	.font-body {
		font-size: 16px;
		line-height: 16px;
	}

	.font-caption {
		font-size: 9.89px;
		line-height: 12px;
	}
}

.gm-style-iw-c,
.gm-style-iw-d {
	background-color: transparent !important;
	box-shadow: none !important;
	max-height: 1000px !important;
	max-width: 1000px !important;
	overflow: hidden !important;
	padding: 0 !important;
}

.gm-style-iw-t::after {
	display: none !important;
}

.gm-style-iw-c [aria-label="Close"] {
	display: none !important;
}

.studio-c-content p {
	display: flex;
	gap: 1rem;
}

@media (max-width: 767px) {
	.studio-c-content p {
		flex-wrap: wrap;
	}
}

.studio-c-content p img {
	width: 50%;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 50%;
}

.studio-c-content p {
	margin-bottom: 16px;
}

@media (min-width: 768px) {
	.proj:nth-child(10n - 9) {
		width: 50%;
		flex: none;
	}

	.proj:nth-child(10n - 9) > * {
		position: relative;
		left: 32px;
	}

	.proj:nth-child(10n - 8) {
		width: 50%;
		flex: none;
	}

	.proj:nth-child(10n - 8) > * {
		position: relative;
		top: -32px;
		left: -32px;
	}

	.proj:nth-child(10n - 7) {
		width: 33.33%;
		flex: none;
	}

	.proj:nth-child(10n - 6) {
		width: 33.33%;
		flex: none;
	}

	.proj:nth-child(10n - 6) > * {
		position: relative;
		top: -32px;
	}

	.proj:nth-child(10n - 5) {
		width: 33.33%;
		flex: none;
	}

	.proj:nth-child(10n - 5) > * {
		position: relative;
		top: 32px;
	}

	.proj:nth-child(10n - 4) {
		width: 50%;
		flex: none;
	}

	.proj:nth-child(10n - 4) > * {
		position: relative;
		top: -32px;
	}

	.proj:nth-child(10n - 3) {
		width: 50%;
		flex: none;
	}

	.proj:nth-child(10n - 3) > * {
		position: relative;
		top: 32px;
		left: -16px;
	}

	.proj:nth-child(10n - 2) {
		width: 33.33%;
		flex: none;
	}

	.proj:nth-child(10n - 1) {
		width: 33.33%;
		flex: none;
	}

	.proj:nth-child(10n - 1) > * {
		position: relative;
		top: 40px;
		left: -32px;
	}

	.proj:nth-child(10n - 0) {
		width: 33.33%;
		flex: none;
	}

	.proj:nth-child(10n - 0) > * {
		position: relative;
		top: -16px;
	}
}

/* siteGPT */
#sitegpt-chat-icon {
	display: none !important;
}

#sitegpt-bot-ui:not(.mobile) {
	left: 1rem;
	right: initial !important;
	bottom: 1rem !important;
}

/* Nav */

.nav-background-zIndex {
	z-index: 51;
}
.nav-zIndex {
	z-index: 52;
}

.nav-zIndex-logotext {
	z-index: 53;
}

.nav-zIndex-coming-soon {
	z-index: 54;
}

.logo {
	animation: pulse 1s ease-in-out 2 alternate;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	height: 640px;
	left: 50%;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%) scale(1);
	width: 640px;
}

.logoOffline {
	animation-iteration-count: infinite;
}

.navLogo {
	animation: none;
	height: 176px;
	left: 152px;
	top: 78px;
	transition: all 0.5s ease-in-out;
	width: 176px;
}

.navLogo.open {
	height: 550px;
	left: 130px;
	top: 80px;
	width: 550px;
}

@media (min-width: 1024px) {
	.logoText {
		padding-left: 72px;
	}
}

@keyframes pulse {
	from {
		transform: translate(-50%, -50%) scale(1);
	}

	to {
		transform: translate(-50%, -50%) scale(1.4);
	}
}

@media (max-width: 1023px) {
	.logo {
		height: 400px;
		width: 400px;
	}

	.navLogo {
		height: 165px;
		left: 92px;
		top: -8px;
		width: 165px;
	}

	.navLogo.open {
		left: 100px;
		top: 50px;
	}
}

@media (max-width: 767px) {
	.logo {
		height: 300px;
		width: 300px;
	}

	.navLogo {
		height: 191px;
		left: 102px;
		top: -16px;
		width: 191px;
	}

	.navLogo.open {
		left: 70px;
		top: 60px;
	}

	@keyframes pulse {
		from {
			transform: translate(-50%, -50%) scale(1);
		}

		to {
			transform: translate(-50%, -50%) scale(1.3);
		}
	}
}

.logoText {
	width: 686px;
}

@media (max-width: 1023px) {
	.logoText {
		width: 616px;
	}
}

@media (max-width: 767px) {
	.logoText {
		width: 80%;
		margin: auto;
	}
}

@keyframes animation-clip {
	0% {
		clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
	}
	50% {
		clip-path: polygon(0% 0%, 70% 0%, 70% 100%, 0% 100%);
	}
	100% {
		clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
	}
}

@keyframes animation-clip-access-denied {
	0% {
		clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
	}
	25% {
		clip-path: polygon(0% 0%, 25% 0%, 25% 100%, 0% 100%);
	}
	50% {
		clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
	}
	75% {
		clip-path: polygon(0% 0%, 75% 0%, 75% 100%, 0% 100%);
	}
	100% {
		clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
	}
}

.animation-clip {
	animation: animation-clip 1.5s infinite steps(3);
}

.animation-clip-access-denied {
	animation: animation-clip-access-denied 1s forwards steps(3);
}

.mainSlider :global(.slick-prev) {
	display: block;
	position: fixed;
	left: 11%;
	z-index: 10;
}

.mainSlider :global(.slick-prev:before) {
	background-image: url(images/prev.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	content: "";
	display: block;
	height: 20px;
	width: 20px;
}

.mainSlider :global(.slick-next) {
	display: block;
	position: fixed;
	left: 50%;
	right: auto;
	z-index: 10;
}

.mainSlider :global(.slick-next:before) {
	background-image: url(images/next.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	content: "";
	display: block;
	height: 20px;
	width: 20px;
}

@media (max-width: 1023px) {
	.mainSlider :global(.slick-prev) {
		left: 8px;
	}

	.mainSlider :global(.slick-next) {
		left: calc(50% - 24px);
	}
}

.bigSlider :global(.slick-prev) {
	display: block;
	position: fixed;
	left: 54px;
	z-index: 10;
}

.bigSlider :global(.slick-prev:before) {
	background-image: url(images/prev.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	content: "";
	display: block;
	height: 30px;
	width: 30px;
}

.bigSlider :global(.slick-next) {
	display: block;
	position: fixed;
	right: 54px;
	z-index: 10;
}

.bigSlider :global(.slick-next:before) {
	background-image: url(images/next.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	content: "";
	display: block;
	height: 30px;
	width: 30px;
}
